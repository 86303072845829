'use client';

import { Hourglass, LucideInfo } from 'lucide-react';
import { useState } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Label,
  Switch,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { humanReadableDurationFromHours } from '@utils';
import { useHandleLink } from '../../server-actions';
import { DailyAvailabilityStats, Link } from '../../domain';

export interface ReviewAvailability {
  status: boolean;
  _links: {
    toggle?: Link;
  };
}

export const ReviewAvailabilityCard = ({
  reviewAvailability,
  availabilityStats,
  locale,
}: {
  reviewAvailability?: ReviewAvailability;
  availabilityStats?: DailyAvailabilityStats;
  locale: string;
}): JSX.Element => {
  const { t } = useClientTranslations();

  if (!reviewAvailability || !availabilityStats) {
    return <></>;
  }

  return (
    <div className="gap-md mobile:gap-md flex w-full flex-col pl-0">
      <div className="gap-xs flex flex-row items-center">
        <Hourglass className="size-6" />
        <h2 className="text-xl">{t('learning.homepage.review-availability.title')}</h2>
      </div>

      <Card className="flex flex-col !p-0">
        <CardContent className="p-lg flex flex-col items-center justify-center">
          <ReviewAvailabilityToggle reviewAvailability={reviewAvailability} />
          <AvailabilityStats availabilityStats={availabilityStats} locale={locale} />
        </CardContent>
      </Card>
    </div>
  );
};

const ReviewAvailabilityToggle = ({ reviewAvailability }: { reviewAvailability: ReviewAvailability }): JSX.Element => {
  const { t } = useClientTranslations();
  const [checked, setChecked] = useState<boolean>(reviewAvailability.status);
  const { isMutating, handleLink } = useHandleLink();

  const handleToggle = async (): Promise<void> => {
    setChecked(!checked);

    if (reviewAvailability._links.toggle) {
      await handleLink(reviewAvailability._links.toggle);
    }
  };

  return (
    <div className="flex items-center text-center">
      <Label
        className={`pr-sm text-md font-normal normal-case ${!checked && !isMutating ? '' : 'text-subtext-disabled'}`}
      >
        {t('learning.homepage.review-availability.unavailable')}
      </Label>
      <Switch disabled={isMutating} checked={checked} onCheckedChange={handleToggle} />
      <Label
        className={`px-sm text-md font-normal normal-case ${checked && !isMutating ? '' : 'text-subtext-disabled'}`}
      >
        {t('learning.homepage.review-availability.available')}
      </Label>
      <Tooltip>
        <TooltipTrigger asChild>
          <LucideInfo className="fill-foreground text-background"></LucideInfo>
        </TooltipTrigger>
        <TooltipContent>{t('learning.homepage.review-availability.explanation')}</TooltipContent>
      </Tooltip>
    </div>
  );
};

const AvailabilityStats = ({
  availabilityStats,
  locale,
}: {
  availabilityStats: DailyAvailabilityStats;
  locale: string;
}): JSX.Element => {
  const { t } = useClientTranslations();

  return (
    <div className="relative h-fit w-fit">
      <CircularProgress progress={(availabilityStats.hoursAvailable * 100) / availabilityStats.hoursTarget} />
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="flex max-w-[128px] flex-col">
          <p className="text-accent text-center text-xl font-bold">
            {`${humanReadableDurationFromHours(availabilityStats.hoursAvailable, locale)} / ${humanReadableDurationFromHours(availabilityStats.hoursTarget, locale)}`}
          </p>
          <p className="text-center text-sm">{t('learning.homepage.review-availability.hours-available')}</p>
        </div>
      </div>
    </div>
  );
};
