'use client';

import { CardLink, CardContent, CardDescription, CardHeader, CardTitle } from '@design-system';
import { Separator } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { SkillElement } from '../skills/skill-element';
import { Project } from '../../domain/project-details/project-details';

export const AllProjects = ({ projects }: { projects: Project[] }): JSX.Element => {
  const { t } = useClientTranslations();

  return (
    <div className="gap-md desktop:grid-cols-3 grid grid-cols-1">
      {projects.map((project) => (
        <CardLink
          data-testid="project-card"
          key={project.id}
          className="gap-lg flex min-h-72 cursor-pointer flex-col"
          href={`/projects/${project.id}`}
        >
          <CardHeader className="gap-sm flex min-h-20">
            <CardTitle className="leading-xl text-base">{project.name}</CardTitle>
            <CardDescription className="text-secondary text-left text-base font-normal">
              {project.abstract}
            </CardDescription>
          </CardHeader>

          <Separator />

          <CardContent className="gap-md flex flex-col">
            <p className="text-sm font-semibold leading-tight">{t('all-projects.related-skills.subtitle')}:</p>
            <ul className="gap-sm flex grid grid-cols-1 flex-wrap">
              {project.skills.map(({ name: skillName, acquired }: { name: string; acquired: boolean }) => (
                <SkillElement name={skillName} key={skillName} acquired={acquired} />
              ))}
            </ul>
          </CardContent>
        </CardLink>
      ))}
    </div>
  );
};
