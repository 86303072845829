import { Link } from '../shared/link';

export enum EvaluationStepStatus {
  COMPLETED = 'COMPLETED',
  UPCOMING = 'UPCOMING',
  CURRENT = 'CURRENT',
}
export interface Evaluation {
  startedAt: Date;
  steps: EvaluationTimeline;
}

export type EvaluationStep = EvaluationStepCompleted | EvaluationStepUpcoming | EvaluationStepCurrent;

export interface EvaluationStepCompleted {
  position: number;
  method: string;
  reviewer: Reviewer;
  _links: Record<string, Link>;
  status: EvaluationStepStatus.COMPLETED;
}

export interface EvaluationStepUpcoming {
  position: number;
  method: string;
  _links: Record<string, Link>;
  status: EvaluationStepStatus.UPCOMING;
}

export interface EvaluationStepCurrent {
  position: number;
  method: string;
  _links: { requestReview?: Link };
  reviewer?: Reviewer;
  status: EvaluationStepStatus.CURRENT;
}

export interface Reviewer {
  lastName: string;
  firstName: string;
}

export type EvaluationTimeline = EvaluationStep[];
