import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, Separator } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { Link } from '../../domain';
import { I18nNamespace } from '../../infrastructure/i18n-namespaces';
import { ReviewCard } from './review-card';

export type ReviewToPerform = {
  reviewee: {
    firstName: string;
    lastName: string;
  };
  stepId: string;
  projectName: string;
  _links: {
    startReview: Link;
  };
};

export const ReviewToPerformCard = ({ reviews }: { reviews: ReviewToPerform[] }): JSX.Element => {
  return (
    <>
      {reviews.length > 0 ? (
        <Card className="flex flex-col !p-0">
          <CardContent data-testid="review-to-perform" className="gap-md p-lg flex flex-col">
            {reviews.map((review, index) => (
              <div key={`review-${index}`}>
                {index > 0 && <Separator className="mb-md" />}
                <ReviewCard review={review} />
              </div>
            ))}
          </CardContent>
        </Card>
      ) : (
        <NoReviewsToPerformCard />
      )}
    </>
  );
};

const NoReviewsToPerformCard = (): JSX.Element => {
  const { t } = useClientTranslations();
  return (
    <Card className="flex flex-col">
      <CardHeader className="gap-xxs flex flex-col">
        <CardTitle>{t('homepage.reviews.no-reviews', { ns: I18nNamespace.LEARNING })}</CardTitle>
        <CardDescription className="text-subtext">
          {t('homepage.reviews.no-reviews.description', { ns: I18nNamespace.LEARNING })}
        </CardDescription>
      </CardHeader>
    </Card>
  );
};
