'use client';

import { FileDown } from 'lucide-react';
import { Button } from '@design-system';
import { useClientSideEnv } from '@core-systems/client-side-configuration';

export const DownloadButton = ({
  href,
  dataTestId,
  children,
  className,
}: {
  href: string;
  dataTestId: string;
  children: React.ReactNode;
  className?: string;
}): JSX.Element => {
  const { fileCdnUri } = useClientSideEnv();

  return (
    <Button
      variant="tertiary"
      data-testid={dataTestId}
      Icon={FileDown}
      href={fileCdnUri + href}
      className={className}
      isExternal
      download
    >
      {children}
    </Button>
  );
};
