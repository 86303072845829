import { Button } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { useHandleLink } from '../../../server-actions/hooks/useHandleLink';
import { Link } from '../../../domain/shared/link';
import { LoadingButton } from '../loading-button';

export const GiveUpAttemptButton = ({
  actions,
  className,
}: {
  actions: { giveUp?: Link };
  className?: string;
}): JSX.Element => {
  const { handleLink, isMutating } = useHandleLink();
  const { t } = useClientTranslations();

  if (actions.giveUp === undefined) {
    return <></>;
  }

  const giveUpAttempt = actions.giveUp;
  return isMutating ? (
    <LoadingButton message={t('loading.attempt.waiting')} variant="destructive" className={className} />
  ) : (
    <Button data-testid="give-up" className={className} variant="destructive" onClick={() => handleLink(giveUpAttempt)}>
      {t('project-details.last-attempt.give-up')}
    </Button>
  );
};
