'use client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { LucideIcon } from 'lucide-react';
import { Separator, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from '@design-system';

type MenuFooterItem = { Icon: LucideIcon; href: string; translationKey: string; key: string; newTab?: boolean };

export type SidebarButtonGroupProps = {
  actionIcon: LucideIcon;
  itemGroups: MenuFooterItem[][];
};

export function SidebarButtonGroup({ itemGroups, actionIcon: ActionIcon }: SidebarButtonGroupProps): React.JSX.Element {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const buttonsListRef = useRef<HTMLLIElement>(null);
  const buttonIds = useMemo(
    () => [...itemGroups.flat().map((item) => item.key), 'button-group-menu', 'button-group-action'],
    [itemGroups],
  );

  useEffect(() => {
    if (buttonsListRef.current) {
      buttonsListRef.current.focus();
    }
  }, [showMenu]);

  return (
    <SidebarMenu
      className="text-subtext"
      onBlur={(event) => {
        if (!buttonIds.some((id) => id === event.relatedTarget?.getAttribute('id'))) {
          setShowMenu(false);
        }
      }}
      onKeyUp={(event) => {
        if (event.key === 'Escape') {
          setShowMenu(false);
        }
      }}
    >
      {showMenu && (
        <SidebarMenuItem tabIndex={-1} ref={buttonsListRef} key="button-group-menu" id="button-group-menu">
          <SidebarMenu className="text-subtext border-1 border--primary-border mb-sm [&_span]:text-foreground [&_svg]:text-foreground rounded-sm border-solid">
            {itemGroups?.map((group, index) => (
              <SidebarMenuItem key={`button-group-menu-${index}`}>
                <SidebarMenu>
                  {group.map(({ Icon, href, translationKey, key, newTab }, indexInGroup) => (
                    <SidebarMenuItem key={`button-group-menu-${index}-item-${key}-${indexInGroup}`}>
                      <SidebarMenuButton asChild>
                        <Link href={href} id={key} target={newTab ? '_blank' : undefined}>
                          <Icon />
                          <span>{translationKey}</span>
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
                {index !== itemGroups.length - 1 && (
                  <div className="flex justify-center" key="button-group-separator">
                    <Separator className="w-[95%]" />
                  </div>
                )}
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarMenuItem>
      )}

      <SidebarMenuItem
        tabIndex={0}
        id="button-group-action"
        key="button-group-action"
        className="bg-secondary-background-disabled flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-sm"
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            setShowMenu(!showMenu);
          }
        }}
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        <ActionIcon />
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
