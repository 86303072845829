'use client';

import { useClientTranslations } from '@core-systems/i18n';
import { Card, CardContent, CardHeader, CardTitle } from '@design-system';
import { ProjectWithLastAttempt } from '../../../domain/project-details/project-details';
import { SkillList } from '../../skills/skill-list';

export function ProjectSkillsCard({ project }: { project: ProjectWithLastAttempt }): JSX.Element {
  const { t } = useClientTranslations();

  return (
    <Card className="gap-md flex w-full flex-col">
      <CardHeader className="gap-md flex">
        <CardTitle className="leading-base text-xl">{t('project.skills.title')}</CardTitle>
      </CardHeader>
      <CardContent className="text-secondary w-full">
        <SkillList skills={project.skills} />
      </CardContent>
    </Card>
  );
}
