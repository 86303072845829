import { Button, useToast } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { useHandleLink } from '../../../server-actions';
import { I18nNamespace } from '../../../infrastructure/i18n-namespaces';
import { Link } from '../../../domain';
import { LoadingButton } from '../loading-button';

export const RequestReviewButton = ({ requestReviewLink }: { requestReviewLink?: Link }): JSX.Element => {
  const { handleLink, isMutating } = useHandleLink();
  const { toast } = useToast();
  const { t } = useClientTranslations();

  if (requestReviewLink === undefined) {
    return <></>;
  }
  return (
    <div className="flex justify-end">
      {isMutating ? (
        <LoadingButton message={t('project-details.last-attempt.request-review', { ns: I18nNamespace.LEARNING })} />
      ) : (
        <Button
          onClick={async () => {
            const result = await handleLink(requestReviewLink);

            if (result?.statusCode && result.statusCode === 400) {
              toast({
                variant: 'warning',
                title: t('project-details.toast.no-reviewer-found.title', { ns: I18nNamespace.LEARNING }),
                description: t('project-details.toast.no-reviewer-found.description', {
                  ns: I18nNamespace.LEARNING,
                }),
              });
            }
          }}
        >
          {t('project-details.last-attempt.request-review', { ns: I18nNamespace.LEARNING })}
        </Button>
      )}
    </div>
  );
};
