import { useClientTranslations } from '@core-systems/i18n';
import { Badge } from '@design-system';

export const AvailabilityBadge = ({ available }: { available: boolean }): JSX.Element => {
  const { t } = useClientTranslations();

  if (available) {
    return <></>;
  }

  return (
    <Badge data-testid="module-unavailable" variant={'disabled'}>
      {t('all-modules.module-unavailable', { ns: 'learning.modules' })}
    </Badge>
  );
};
