'use client';

import { useRouter } from 'next/navigation';
import { Button } from '@design-system';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@design-system';

import { Separator } from '@design-system';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { ModuleSkillsList } from '../module-detail/module-skills-list';
import { Module } from '../../domain/module-details/module-details';
import { SkillsProgressionBar } from './skills-progression-bar';
import { AvailabilityBadge } from './availability-badge';

export default function ModulesDrawer({ module }: { module: Module }): JSX.Element {
  const { t } = useClientTranslations();
  const router = useRouter();

  return (
    <Drawer>
      <Card className="gap-md flex flex-col items-stretch justify-between">
        <CardHeader className="gap-sm flex flex-row items-start justify-between space-y-0">
          <CardTitle className="leading-xl text-xl">{module.name}</CardTitle>
          <AvailabilityBadge available={module.available} />
        </CardHeader>
        <Separator />
        <CardContent className="gap-xl flex basis-2/4 flex-col">
          <p className="text-secondary text-base font-normal">{module.abstract}</p>
          <SkillsProgressionBar skills={module.skills} />
        </CardContent>
        <CardFooter className="xs:flex-row flex basis-1/4 flex-col flex-wrap gap-2">
          <DrawerTrigger asChild>
            <Button className="w-full flex-1" variant="secondary">
              {t('actions.view-skills')}
            </Button>
          </DrawerTrigger>
          <Button data-testid="view-module" className="w-full flex-1" href={`/modules/${module.id}`}>
            {t('actions.view-module')}
          </Button>
        </CardFooter>
      </Card>

      <DrawerContent className="gap-xl flex flex-col">
        <DrawerHeader className="gap-md flex flex-col">
          <DrawerTitle className="leading-xl text-left text-xl font-semibold">{module.name}</DrawerTitle>
          <Separator />
          <DrawerDescription className="text-subtext text-left text-base font-normal">
            {module.abstract}
          </DrawerDescription>
        </DrawerHeader>
        <ModuleSkillsList module={module} />
        <DrawerFooter>
          <DrawerClose asChild>
            <Button variant="secondary">{t('actions.close', { ns: 'learning' })}</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
