export enum I18nNamespace {
  HOMEPAGE = 'learning.homepage',
  LEARNING = 'learning',
  MENU = 'learning.menu',
  MODULES = 'learning.modules',
  NO_ONGOING_PROGRAM = 'learning.no-ongoing-program',
  PROFILE = 'learning.profile',
  PROJECTS = 'learning.projects',
  REVIEW_FORM = 'learning.review-form',
  RUSHES = 'learning.rushes',
  COMMON = 'common',
}
