'use client';

import { useCallback, useEffect } from 'react';

type FetchWithIntervalProps<T> = {
  initialValue: T;
  setValue: (value: T) => void;
  serverAction: () => Promise<T>;
  refreshInterval?: number;
  deactivate?: boolean;
};

export type FetchWithInterval<T> = ({
  refreshInterval,
  serverAction,
  setValue,
  initialValue,
}: FetchWithIntervalProps<T>) => void;

export function useFetchWithInterval<T>({
  setValue,
  refreshInterval = 30000,
  serverAction,
  initialValue,
  deactivate,
}: FetchWithIntervalProps<T>): void {
  const fetch = useCallback(async (): Promise<void> => {
    const res = await serverAction();
    setValue(res);
  }, [serverAction, setValue]);

  useEffect(() => {
    setValue(initialValue);
    if (deactivate) {
      return;
    }
    const timeoutId = setInterval(fetch, refreshInterval);
    return (): void => {
      clearInterval(timeoutId);
    };
  }, [serverAction, initialValue, setValue, refreshInterval, deactivate, fetch]);
}
