'use client';

import { Badge, CardContent, CardHeader, CardLink, CardTitle } from '@design-system';
import { Separator } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { SkillElement } from '../skills/skill-element';
import { ModuleDetails, RelatedProject } from '../../domain/module-details/module-details';
import { ModuleSkillsList } from './module-skills-list';

export function ModuleDetail({ module }: { module: ModuleDetails }): JSX.Element {
  const { t } = useClientTranslations();

  return (
    <div className="gap-lg flex flex-col">
      <div className="gap-xl flex flex-col">
        <div className="gap-md flex flex-col items-start justify-start">
          <h3 className="text-xxl leading-2xl desktop:basis-1/4 mobile:basis-3/4 self-stretch">{module.name}</h3>
          <p className="text-secondary self-stretch">{module.abstract}</p>
        </div>
        <ModuleSkillsList module={module} display="horizontal" />
        <Separator />
        <div className="gap-xl flex flex-col">
          <h2 className="text-2xl">{t('module-details.related-projects.title', { ns: 'learning.modules' })}</h2>
          <div className="gap-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
            {module?.projects?.map((project) => <ProjectCard key={project.name} project={project} />)}
          </div>
        </div>
      </div>
    </div>
  );
}

const ProjectCard = ({ project }: { project: RelatedProject }): JSX.Element => {
  const { t } = useClientTranslations();

  return (
    <CardLink
      data-testid="project-card"
      key={project.id}
      className="gap-md flex cursor-pointer flex-col"
      href={`/projects/${project.id}`}
    >
      <CardHeader className="gap-md flex flex-row items-center justify-between space-y-0 self-stretch">
        <CardTitle className="leading-xl text-xl">{project.name}</CardTitle>
        <AvailabilityBadge available={project.available} />
      </CardHeader>
      <CardContent className="gap-lg flex basis-1/2 flex-col">
        <Separator />
        <div className="gap-md flex flex-col">
          <h1 className="text-sm font-semibold uppercase leading-tight">
            {t('module-details.related-projects.skills.subtitle', { ns: 'learning.modules' })}
          </h1>
          <div className="gap-xs grid grid-cols-1 flex-wrap">
            {project?.skills.map(({ name: skillName, acquired }: { name: string; acquired: boolean }) => (
              <SkillElement name={skillName} key={skillName} acquired={acquired} />
            ))}
          </div>
        </div>
      </CardContent>
    </CardLink>
  );
};

const AvailabilityBadge = ({ available }: { available: boolean }): JSX.Element => {
  const { t } = useClientTranslations();

  if (available) {
    return <></>;
  }

  return (
    <Badge data-testid="project-unavailable" variant={'disabled'}>
      {t('module-details.related-projects.unavailable', { ns: 'learning.modules' })}
    </Badge>
  );
};
