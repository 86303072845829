import { X } from 'lucide-react';
import { Badge, Card, CardContent, CardTitle } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { FORMAT_PATTERN, formatToHumanReadable } from '@utils';
import { AttemptAbandoned } from '../../../domain/attempt/attempt';
import { AttemptCriteria } from './attempt-criteria';

export const AttemptAbandonedCard = ({
  attempt,
  locale,
}: {
  attempt: AttemptAbandoned;
  locale: string;
}): JSX.Element => {
  const { t } = useClientTranslations();

  const endedAt = formatToHumanReadable(attempt.endedAt, locale, FORMAT_PATTERN.DATE_FORMAT_COMPACT);

  return (
    <Card data-testid="attempt-abandoned-card" className="gap-xxl flex flex-col sm:flex-row">
      <div className="gap-lg flex inline-flex basis-1/2 flex-col items-end justify-start">
        <div className="gap-md flex flex-col items-start justify-start self-stretch">
          <CardTitle className="leading-xl text-xl">{t('project-details.last-attempt.title')}</CardTitle>
          <CardContent className="gap-xs flex flex-col">
            <div className="gap-xs text-md flex flex-row">
              <p className="font-bold">{t('project-details.last-attempt.status')}:</p>
              <Badge variant="error" RightIcon={X}>
                {t('project-details.last-attempt.status.abandoned')}
              </Badge>
            </div>
            <AttemptCriteria label={t('project-details.last-attempt.abandoned-date')} value={endedAt} />
          </CardContent>
        </div>
      </div>
    </Card>
  );
};
