import { Evaluation } from '../evaluation/evaluation';
import { Link } from '../shared/link';

export enum AttemptStatus {
  'IN_PROGRESS' = 'IN_PROGRESS',
  'FAILED' = 'FAILED',
  'SUCCESSFUL' = 'SUCCESSFUL',
  'UNDER_EVALUATION' = 'UNDER_EVALUATION',
  'ABANDONED' = 'ABANDONED',
}

export interface AttemptInProgress {
  startedAt: Date;
  status: AttemptStatus.IN_PROGRESS;
  _links: { giveUp: Link; startEvaluation: Link };
}

export interface AttemptUnderEvaluation {
  status: AttemptStatus.UNDER_EVALUATION;
  evaluation: Evaluation;
  _links: { giveUp: Link };
}

export interface AttemptFailed {
  endedAt: Date;
  status: AttemptStatus.FAILED;
  evaluation: Evaluation;
}

export interface AttemptAbandoned {
  endedAt: Date;
  status: AttemptStatus.ABANDONED;
  evaluation?: Evaluation;
}

export interface AttemptSuccessful {
  endedAt: Date;
  status: AttemptStatus.SUCCESSFUL;
  evaluation: Evaluation;
  _links: { giveUp: Link };
}

export const attemptIsUnderEvaluation = (attempt?: Attempt): attempt is AttemptUnderEvaluation => {
  return attempt !== undefined && attempt.status === AttemptStatus.UNDER_EVALUATION;
};

export type Attempt = AttemptInProgress | AttemptFailed | AttemptAbandoned | AttemptSuccessful | AttemptUnderEvaluation;
