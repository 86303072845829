import { Avatar, AvatarFallback, AvatarImage } from '@design-system';
import { initialsOf } from '@core-systems/utils';
import { TeamMember } from '../../domain';

export const TeamMemberComponent = ({ teamMember }: { teamMember?: TeamMember }): JSX.Element => {
  if (!teamMember) {
    return <></>;
  }

  const fullName = `${teamMember.firstName} ${teamMember.lastName}`;

  return (
    <div className="gap-sm flex h-8 flex-row items-center justify-start">
      <Avatar className="!size-xl">
        <AvatarImage src="/assets/shadcn.png" alt="@shadcn" />
        <AvatarFallback className="bg-accent text-foreground text-sm">{initialsOf(fullName)}</AvatarFallback>
      </Avatar>
      {fullName}
    </div>
  );
};
