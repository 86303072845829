import { isLiteralObject } from '@utils';
import { auth } from '../../auth/server/auth';
import getHttpConfiguration from '../configuration';

type BodyAllowingJson = BodyInit | Record<string, unknown>;
export type RequestOptions = {
  headers?: Record<string, string>;
  cache?: 'no-cache' | 'default' | 'reload' | 'force-cache' | 'only-if-cached';
  revalidate?: number | false;
  tags?: string[];
  _withRetry?: boolean;
  _withAuth?: boolean;
  accessToken?: string;
};
type AllRequestOptions = RequestOptions & {
  body?: BodyAllowingJson;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  _retry?: number;
};
type FetchOptions = Omit<RequestInit, 'headers'> & {
  _withAuth: boolean;
  _retry?: number;
  _withRetry?: boolean;
  headers: Record<string, string>;
};

class HttpClient {
  private async request<Response>(url: string, options: AllRequestOptions): Promise<Response> {
    'use server';
    try {
      const configuration = await getHttpConfiguration();
      const fetchOptions = await this.toFetchOptions(options);
      const response = await fetch(`${configuration.restApiUri}${url}`, fetchOptions);
      if (!response.ok) {
        if (options._withRetry && !options._retry) {
          return await this.retryRequest(response.status, url, options);
        }
        const error = await this.readHttpErrorFrom(response);
        return Promise.reject(error);
      }
      const dataAsText = await response.text();
      return dataAsText ? JSON.parse(dataAsText) : null;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  private async readHttpErrorFrom(response: Response): Promise<any> {
    const rawResponseBody = await response.text();
    try {
      return { status: response.status, ...JSON.parse(rawResponseBody) };
    } catch {
      return { status: response.status, message: rawResponseBody };
    }
  }

  private async retryRequest<Response>(status: number, url: string, options: AllRequestOptions): Promise<Response> {
    if (!options._retry) {
      return this.request<Response>(url, { ...options, _retry: 1 });
    } else {
      return Promise.reject({ status });
    }
  }

  private async addBearerTokenToRequest(options: FetchOptions, accessToken?: string): Promise<FetchOptions> {
    if (!options._withAuth) {
      return options;
    }

    if (accessToken) {
      return { ...options, headers: { ...options.headers, Authorization: `Bearer ${accessToken}` } };
    }

    const session = await auth();
    if (session) {
      return { ...options, headers: { ...options.headers, Authorization: `Bearer ${session.accessToken}` } };
    }

    return options;
  }

  private addBodyToRequest(options: FetchOptions, body?: BodyAllowingJson): FetchOptions {
    if (isLiteralObject(body)) {
      return {
        ...options,
        headers: { ...options.headers, 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };
    }
    return { ...options, body } as FetchOptions;
  }

  private async toFetchOptions(options: AllRequestOptions): Promise<FetchOptions> {
    const headers = options.headers ?? {};
    headers.Accept ??= 'application/json';

    let fetchOptions: FetchOptions = {
      cache: options.cache,
      headers,
      next: {
        revalidate: options.revalidate,
        tags: options.tags,
      },
      _retry: options._retry,
      _withAuth: options._withAuth ?? true,
      method: options.method,
    };

    fetchOptions = await this.addBearerTokenToRequest(fetchOptions, options.accessToken);
    fetchOptions = this.addBodyToRequest(fetchOptions, options.body);

    return fetchOptions;
  }

  async get<Response = void>(url: string, options?: RequestOptions): Promise<Response> {
    'use server';
    return this.request<Response>(url, { method: 'GET', ...options });
  }

  async patch<Response = void>(url: string, body?: BodyAllowingJson, options?: RequestOptions): Promise<Response> {
    'use server';
    return this.request<Response>(url, { method: 'PATCH', body, ...options });
  }

  async post<Response = void>(url: string, body?: BodyAllowingJson, options?: RequestOptions): Promise<Response> {
    'use server';
    return this.request<Response>(url, { method: 'POST', body, ...options });
  }

  async put<Response = void>(url: string, body?: BodyAllowingJson, options?: RequestOptions): Promise<Response> {
    'use server';
    return this.request<Response>(url, { method: 'PUT', body, ...options });
  }

  async delete<Response = void>(url: string, body?: BodyAllowingJson, options?: RequestOptions): Promise<Response> {
    'use server';
    return this.request<Response>(url, { method: 'DELETE', body, ...options });
  }
}

const HttpFactory = ((): { getInstance: () => HttpClient } => {
  let instance: HttpClient | null = null;
  return {
    getInstance: (): HttpClient => {
      if (instance === null) {
        instance = new HttpClient();
      }
      return instance;
    },
  };
})();

export const httpClient = HttpFactory.getInstance();
