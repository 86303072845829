import { Card, CardContent, CardTitle } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { FORMAT_PATTERN, formatToHumanReadable } from '@utils';
import { AttemptUnderEvaluation } from '../../../domain';
import { AttemptCriteria } from './attempt-criteria';
import { GiveUpAttemptButton } from './give-up-attempt-button';

export const AttemptUnderEvaluationCard = ({
  attempt: { evaluation, _links },
  locale,
}: {
  attempt: AttemptUnderEvaluation;
  locale: string;
}): JSX.Element => {
  const { t } = useClientTranslations();

  const evaluationStartedAt = formatToHumanReadable(evaluation.startedAt, locale, FORMAT_PATTERN.DATE_FORMAT_COMPACT);

  return (
    <Card data-testid="attempt-under-evaluation-card" className="gap-xxl flex flex-col sm:flex-row">
      <div className="gap-lg flex inline-flex w-full flex-col items-end justify-start">
        <div className="gap-md flex flex-col items-start justify-start self-stretch">
          <CardTitle className="leading-xl text-xl">{t('project-details.last-attempt.title')}</CardTitle>
          <CardContent className="gap-xs flex flex-col">
            <AttemptCriteria
              label={t('project-details.last-attempt.status')}
              value={t('project-details.last-attempt.status.under-evaluation')}
            />
            <AttemptCriteria
              label={t('project-details.last-attempt.ready-to-be-evaluated-date')}
              value={evaluationStartedAt}
            />
          </CardContent>
        </div>

        <GiveUpAttemptButton actions={_links} className="w-full shrink grow sm:w-auto" />
      </div>
    </Card>
  );
};
