import { Logger } from '../../logger/index';
import { isHttpError } from '../value-objects/http-error';
import { FailableServerAction } from './failable-server-action';

export async function failableAction<T>(
  caller: (...args: any[]) => any,
  httpAction: () => Promise<FailableServerAction<T>>,
): Promise<FailableServerAction<T>> {
  const callerName = caller.name;
  try {
    return await httpAction();
  } catch (error: any) {
    new Logger(callerName).error(error);
    if (isHttpError(error)) {
      return { error: error.message, statusCode: error.status, errorCode: error.errorCode };
    }
    const e = error as Error;
    return { error: `Error with ${callerName}: ${e.message}` };
  }
}
