'use client';

import { Button } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { I18nNamespace } from '../../infrastructure/i18n-namespaces';
import { useHandleLink } from '../../server-actions/hooks/useHandleLink';
import { Link } from '../../domain/shared/link';

export const RegisterButton = ({ link, className }: { link: Link | undefined; className?: string }): JSX.Element => {
  const { t } = useClientTranslations([I18nNamespace.RUSHES]);
  const { handleLink, isMutating } = useHandleLink();

  if (!link) {
    return <></>;
  }

  return (
    <Button
      data-testid="register-to-rush-button"
      variant="primary"
      className={className}
      loading={isMutating}
      onClick={() => handleLink(link)}
    >
      {t('rush.register-button.action')}
    </Button>
  );
};
