'use client';

import { useState, useEffect } from 'react';
import { useIsMobile } from '@design-system';
import { Module } from '../../domain/module-details/module-details';
import ModulesDrawer from './modules.drawer';

import { ModulesDialog } from './modules.dialog';

export const AllModules = ({ modules }: { modules: Module[] }): JSX.Element => {
  const isMobile = useIsMobile();
  const [skillsComponent, setSkillsComponent] = useState<JSX.Element[] | null>(null);

  useEffect(() => {
    if (isMobile) setSkillsComponent(modules.map((module) => <ModulesDrawer module={module} key={module.name} />));
    else setSkillsComponent(modules.map((module) => <ModulesDialog module={module} key={module.name} />));
  }, [isMobile, modules]);

  return <div className="gap-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">{skillsComponent}</div>;
};
