import * as z from 'zod';
import { type User } from 'next-auth';
import { httpClient } from '../../http';
import { AuthError } from '../types/auth-error';

type Credentials = Partial<Record<'email' | 'password', unknown>>;

const LoginResponseSchema = z.object({
  accessToken: z.string(),
  expiresIn: z.number(),
  refreshToken: z.string(),
  refreshExpiresIn: z.number(),
});

export async function authorizeCredentials(credentials: Credentials): Promise<User | null> {
  /**
   * This function builds the user object which will be forwarded as an argument to
   * the `signIn`, `jwt` and `session` callbacks (in that order)
   * The user id is not present here: it will be extracted from the deciphered `accessToken` by next-auth between
   * the callback `authorize` and the callback `signIn`
   */
  try {
    const tokens = await httpClient.post<z.infer<typeof LoginResponseSchema>>('/v1/auth/login', credentials, {
      _withAuth: false,
      _withRetry: true,
      headers: { 'Content-Type': 'application/json' },
    });
    try {
      LoginResponseSchema.parse(tokens);
    } catch {
      throw new Error(AuthError.MISSING_DATA_IN_RESPONSE);
    }
    return {
      accessToken: tokens.accessToken,
      accessTokenExpiresIn: tokens.expiresIn,
      refreshToken: tokens.refreshToken,
      refreshTokenExpiresIn: tokens.refreshExpiresIn,
    };
  } catch (error) {
    throw new Error((error as any).status === 401 ? AuthError.INVALID_CREDENTIALS : AuthError.UNHANDLED_SERVER_ERROR);
  }
}

export async function authorizeGoogleAccessToken({
  accessToken,
}: Partial<Record<'accessToken', unknown>>): Promise<User | null> {
  /**
   * This function builds the user object which will be forwarded as an argument to
   * the `signIn`, `jwt` and `session` callbacks (in that order)
   * The user id is not present here: it will be extracted from the deciphered `accessToken` by next-auth between
   * the callback `authorize` and the callback `signIn`
   */
  try {
    const tokens = await httpClient.post<z.infer<typeof LoginResponseSchema>>(
      '/v1/auth/login-with-google',
      { accessToken },
      {
        _withAuth: false,
        _withRetry: true,
        headers: { 'Content-Type': 'application/json' },
      },
    );
    try {
      LoginResponseSchema.parse(tokens);
    } catch {
      throw new Error(AuthError.MISSING_DATA_IN_RESPONSE);
    }
    return {
      accessToken: tokens.accessToken,
      accessTokenExpiresIn: tokens.expiresIn,
      refreshToken: tokens.refreshToken,
      refreshTokenExpiresIn: tokens.refreshExpiresIn,
    };
  } catch (error) {
    const thrownError = new Error(
      (error as any).status === 401 ? AuthError.INVALID_AUTHORIZATION_CODE : AuthError.UNHANDLED_SERVER_ERROR,
    );
    throw thrownError;
  }
}
