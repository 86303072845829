'use client';

import { useRouter } from 'next/navigation';
import { Button } from '@design-system';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@design-system';

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@design-system';
import { Separator } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { ModuleSkillsList } from '../module-detail/module-skills-list';
import { Module } from '../../domain/module-details/module-details';
import { AvailabilityBadge } from './availability-badge';
import { SkillsProgressionBar } from './skills-progression-bar';

export const ModulesDialog = ({ module }: { module: Module }): JSX.Element => {
  const { t } = useClientTranslations();
  const router = useRouter();

  return (
    <Dialog>
      <Card className="gap-lg flex flex-col items-stretch justify-between">
        <CardHeader className="gap-md flex flex-col">
          <div className="gap-md flex flex-row items-center justify-between space-y-0 self-stretch">
            <CardTitle className="leading-xl text-xl">{module.name}</CardTitle>
            <AvailabilityBadge available={module.available} />
          </div>
          <Separator />
        </CardHeader>

        <CardContent className="gap-xl flex h-full w-full flex-col justify-start">
          <p className="text-secondary">{module.abstract}</p>
        </CardContent>

        <CardFooter className="gap-lg flex flex-col">
          <div className="w-full">
            <SkillsProgressionBar skills={module.skills} />
          </div>

          <div className="gap-sm flex w-full flex-wrap lg:flex-row">
            <DialogTrigger asChild>
              <Button className="w-full flex-1" variant="secondary">
                {t('actions.view-skills')}
              </Button>
            </DialogTrigger>
            <Button data-testid="view-module" className="w-full flex-1" href={`/modules/${module.id}`}>
              {t('actions.view-module')}
            </Button>
          </div>
        </CardFooter>
      </Card>

      <DialogContent className="gap-xl flex w-full flex-col justify-between">
        <DialogHeader className="gap-md flex flex-col">
          <DialogTitle className="leading-xl text-left text-xl">{module.name}</DialogTitle>
          <Separator />
          <DialogDescription className="text-secondary text-left">{module.abstract}</DialogDescription>
        </DialogHeader>
        <ModuleSkillsList module={module} />
        <DialogFooter className="!justify-end">
          <DialogClose asChild>
            <Button variant="secondary">{t('actions.close', { ns: 'learning' })}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
