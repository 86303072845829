'use client';

import { Button } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { useHandleLink } from '../../../server-actions';
import { Link } from '../../../domain';
import { I18nNamespace } from '../../../infrastructure/i18n-namespaces';
import { LoadingButton } from '../loading-button';

export const StartAnAttemptButton = ({ actions }: { actions: { startAnAttempt?: Link } }): JSX.Element => {
  const { t } = useClientTranslations();
  const { handleLink, isMutating } = useHandleLink();

  if (isMutating) {
    return <LoadingButton message={t('loading.attempt.starting')} />;
  }

  const startAnAttempt = actions.startAnAttempt;
  if (startAnAttempt === undefined) {
    return <></>;
  }

  return (
    <Button data-testid="start-attempt" onClick={() => handleLink(startAnAttempt)} className="xs:w-auto w-full">
      {t('project-details.start-attempt', { ns: I18nNamespace.PROJECTS })}
    </Button>
  );
};
