'use client';

import { Step, Stepper } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { EvaluationStepStatus, EvaluationTimeline } from '../../../domain/evaluation/evaluation';
import { I18nNamespace } from '../../../infrastructure/i18n-namespaces';

export const EvaluationTimelineStepper = ({
  evaluationTimeline,
}: {
  evaluationTimeline: EvaluationTimeline;
}): JSX.Element => {
  const { t } = useClientTranslations();

  const steps = evaluationTimeline
    .map((step) => {
      const title = t(`project-details.last-attempt.evaluation.${step.method}`, { ns: I18nNamespace.PROJECTS });

      if (step.status === EvaluationStepStatus.COMPLETED) {
        return {
          variant: 'success',
          collapseStatus: 'closed',
          title,
          description: t('project-details.evaluation.review.completed', {
            firstName: step.reviewer.firstName,
            lastName: step.reviewer.lastName,
            ns: I18nNamespace.PROJECTS,
          }),
        };
      }

      if (step.status === EvaluationStepStatus.CURRENT && step.reviewer) {
        return {
          variant: 'warning',
          collapseStatus: 'opened',
          title,
          description: t('project-details.evaluation.review.waiting', {
            firstName: step.reviewer.firstName,
            lastName: step.reviewer.lastName,
            ns: I18nNamespace.PROJECTS,
          }),
        };
      }

      if (step.status === EvaluationStepStatus.CURRENT && step.reviewer === undefined) {
        return {
          variant: 'primary',
          collapseStatus: 'opened',
          title,
          description: t('project-details.evaluation.review.looking-for-reviewer', { ns: I18nNamespace.PROJECTS }),
        };
      }

      return {
        variant: 'disabled',
        collapseStatus: 'closed',
        title,
      };
    })
    .filter((step) => step !== undefined);

  return <Stepper steps={steps as Step[]}></Stepper>;
};
