'use client';

import { useRouter } from 'next/navigation';
import { useCallback, useState, useTransition } from 'react';
import { useToast } from '@design-system';

import { FailableServerAction, hasInternalServerError } from '@core-systems/http';
import { performServerAction } from '../perform-server-action';
import { Link } from '../../domain/shared/link';

export const useHandleLink = (): {
  handleLink: (action: Link, body?: any, refreshPage?: boolean) => Promise<FailableServerAction<any>>;
  isMutating: boolean;
} => {
  const [isPending, setPending] = useState(false);
  const [isTransitionStarted, startTransition] = useTransition();
  const router = useRouter();
  const isMutating = isPending || isTransitionStarted;

  const { toast } = useToast();
  const handleLink = useCallback(
    async (action: Link, body?: any, refreshPage: boolean = true) => {
      setPending(true);

      const result = await performServerAction(action, body);

      if (hasInternalServerError(result)) {
        toast({
          variant: 'error',
          title: 'We are sorry',
          description: 'Something went wrong',
        });
      }
      if (refreshPage) {
        startTransition(router.refresh);
      }

      setPending(false);

      return result;
    },
    [router.refresh, toast],
  );

  return { handleLink, isMutating };
};
