'use client';

import { useClientTranslations } from '@core-systems/i18n';
import { Card, CardContent, CardHeader, CardTitle, Separator } from '@design-system';
import { RushDetails } from '../../domain';
import { I18nNamespace } from '../../infrastructure/i18n-namespaces';
import { TeamMemberComponent } from '../teams/team-member';
import { DownloadSubjectButton } from './download-subject-button';
import { RegisterButton } from './register-to-rush-button';
import { UnregisterButton } from './unregister-from-rush-button';

export function RushDetailsPage({ rush }: { rush: RushDetails }): JSX.Element {
  const { t } = useClientTranslations([I18nNamespace.RUSHES]);
  return (
    <div className="gap-md gap-lg flex w-full flex-col">
      <div className="gap-md xs:items-center xs:flex-row flex flex-col justify-start">
        <h3 className="leading-2xl shrink grow text-xl sm:text-3xl">{rush.name}</h3>
        <RegisterButton link={rush._links.register} />
        <UnregisterButton link={rush._links.unregister} />
      </div>

      <div className="gap-sm desktop:flex-row mobile:flex-col flex w-full">
        <div className="desktop:basis-1/2 flex w-full">
          <RushAbstractCard rush={rush} />
        </div>
        <div className="gap-md gap-sm desktop:basis-1/2 flex h-fit flex-col">
          <Team rush={rush} />
        </div>
      </div>
    </div>
  );
}

function RushAbstractCard({ rush }: { rush: RushDetails }): JSX.Element {
  const { t } = useClientTranslations([I18nNamespace.RUSHES]);

  return (
    <Card className="gap-lg flex w-full flex-col">
      <CardHeader className="gap-md flex flex-col justify-between sm:flex-row">
        <CardTitle className="leading-base text-xl">{t('rush-details.abstract')}</CardTitle>
        <div className="gap-sm xs:flex-row flex flex-col">
          <DownloadSubjectButton href={rush._links.downloadSubject?.href} />
        </div>
      </CardHeader>

      <CardContent className="gap-lg flex flex-col">
        <Separator />
        <p className="text-secondary whitespace-break-spaces">{rush.abstract}</p>
      </CardContent>
    </Card>
  );
}

function Team({ rush }: { rush: RushDetails }): JSX.Element {
  const { t } = useClientTranslations([I18nNamespace.RUSHES]);
  if (!rush.team) return <></>;

  return (
    <Card className="gap-md flex w-full flex-col">
      <CardHeader className="gap-md flex flex-row justify-between">
        <CardTitle className="leading-base text-xl">{t('rush-details.teammates')}</CardTitle>
      </CardHeader>
      <CardContent className="gap-xs flex flex-col">
        {rush.team.map((member, index) => (
          <TeamMemberComponent key={`member-${index}`} teamMember={member} />
        ))}
      </CardContent>
    </Card>
  );
}
