export enum MENU_ITEMS_KEY {
  HOME = 'HOME',
  MODULES = 'MODULES',
  PROJECTS = 'PROJECTS',
  NO_ONGOING_PROGRAM = 'NO_ONGOING_PROGRAM',
}

export type MenuTabs = MENU_ITEMS_KEY[];

export type MenuTabsResponse = {
  tabs: MenuTabs;
};
