'use client';

import { useClientTranslations } from '@core-systems/i18n';
import { Module } from '../../domain/module-details/module-details';
import { SkillList } from '../skills/skill-list';

export function ModuleSkillsList({
  module,
  display = 'vertical',
}: {
  module: Module;
  display?: 'vertical' | 'horizontal';
}): JSX.Element {
  const { t } = useClientTranslations();

  return (
    <div className="gap-sm flex w-full flex-col">
      <p className="text-sm font-semibold uppercase leading-tight">
        {t('module-details.related-projects.skills.subtitle')}
      </p>
      <SkillList skills={module.skills} display={display} />
    </div>
  );
}
