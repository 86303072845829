'use client';
import { ProgressBar } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { Skill } from '../../domain/shared/skill';

export const SkillsProgressionBar = ({ skills }: { skills: Skill[] }): JSX.Element => {
  const { t } = useClientTranslations();
  const acquiredSkills = skills.filter((skill) => skill.acquired).length;
  const totalSkills = skills.length;

  return (
    <ProgressBar label={t('all-modules.progression')} currentValue={acquiredSkills} completedValue={totalSkills} />
  );
};
