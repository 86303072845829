import { Card, CardContent, CardHeader, CardTitle, Separator } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { I18nNamespace } from '../../../infrastructure/i18n-namespaces';
import { DownloadResourcesButton } from '../../project-annexes/download-resources-button';
import { DownloadSubjectButton } from '../../project-annexes/download-subject-button';
import { ProjectWithLastAttempt } from '../../../domain';

export function ProjectAbstractCard({ project }: { project: ProjectWithLastAttempt }): JSX.Element {
  const { t } = useClientTranslations([I18nNamespace.PROJECTS]);

  return (
    <Card className="gap-lg flex w-full flex-col">
      <CardHeader className="gap-md flex flex-col justify-between">
        <CardTitle className="leading-base text-xl">{t('project-details.abstract')}</CardTitle>
        <div className="gap-sm flex flex-col sm:flex-row">
          <DownloadSubjectButton
            href={project._links.downloadSubject?.href}
            className="w-full shrink grow basis-1/2 sm:w-auto"
          />
          <DownloadResourcesButton
            href={project._links.downloadResources?.href}
            className="w-full shrink grow basis-1/2 sm:w-auto"
          />
        </div>
      </CardHeader>
      <Separator />
      <CardContent className="gap-lg flex w-full flex-col">
        <p className="text-secondary whitespace-break-spaces">{project.abstract}</p>
      </CardContent>
    </Card>
  );
}
