import { Loader2 } from 'lucide-react';
import { Button } from '@design-system';

export const LoadingButton = ({
  message,
  variant = 'primary',
  className,
}: {
  message: string;
  variant?: 'primary' | 'secondary' | 'destructive';
  className?: string;
}): JSX.Element => {
  return (
    <Button data-testid="loading-button" disabled loading Icon={Loader2} className={className} variant={variant}>
      {message}
    </Button>
  );
};
