'use client';

import { useRouter } from 'next/navigation';
import { useClientTranslations } from '@core-systems/i18n';
import { Button } from '@design-system';

export const GoToRushButton = ({ rushId, className }: { rushId: string; className: string }): JSX.Element => {
  const router = useRouter();
  const { t } = useClientTranslations();

  return (
    <Button
      data-testid="go-to-rush-button"
      variant="secondary"
      className={className}
      onClick={() => router.push(`/rushes/${rushId}`)}
    >
      {t('learning.homepage.go-to-rush')}
    </Button>
  );
};
