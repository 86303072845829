'use client';

import { Card, CardContent, ProgressBar } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { DailyReviewStats } from '../../domain';
import { I18nNamespace } from '../../infrastructure/i18n-namespaces';

export const DailyReviewStatsCard = ({ stats }: { stats?: DailyReviewStats }): JSX.Element => {
  const { t } = useClientTranslations();

  if (stats === undefined) {
    return <></>;
  }
  const { performedReviews, dailyTarget } = stats;

  return (
    <Card className="flex flex-col !p-0">
      <CardContent className="gap-md p-lg flex flex-col">
        <ProgressBar
          label={t('homepage.reviews.stats.progression.title', { ns: I18nNamespace.LEARNING })}
          currentValue={performedReviews}
          completedValue={dailyTarget}
        />
        <ReviewEncouragementMessage performedReviews={performedReviews} dailyTarget={dailyTarget} />
      </CardContent>
    </Card>
  );
};

const ReviewEncouragementMessage = ({
  performedReviews,
  dailyTarget,
}: {
  performedReviews: number;
  dailyTarget: number;
}): JSX.Element => {
  const { t } = useClientTranslations();
  if (performedReviews === 0) {
    return <></>;
  }
  const ratio = performedReviews / dailyTarget;
  const level = getMessageLevelFor(ratio);

  const titleKey = `homepage.reviews.stats.encouragement.${level}.title`;
  const messageKey = `homepage.reviews.stats.encouragement.${level}.message`;

  return (
    <Card className="p-sm flex flex-col" data-testid="review-encouragement">
      <CardContent className="gap-sm flex flex-row items-center">
        <div className="border-accent border-1 px-md flex h-9 w-9 items-center justify-center rounded-full">
          {performedReviews}
        </div>
        <div>
          <p className="text-base">{t(titleKey, { ns: I18nNamespace.LEARNING })}</p>
          <p className="text-sm">{t(messageKey, { ns: I18nNamespace.LEARNING })}</p>
        </div>
      </CardContent>
    </Card>
  );
};

function getMessageLevelFor(ratio: number): 'first' | 'halfway' | 'last' {
  if (ratio >= 1) {
    return 'last';
  }
  if (ratio >= 0.5) {
    return 'halfway';
  }
  return 'first';
}
