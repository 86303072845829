import { ServerActionError } from '../types/server-action-error';

// TODO temporary console error pending new alerting provider
/* eslint-disable no-console */

export function hasFailed(result: FailableServerAction): result is ServerActionError {
  const isError = (result as ServerActionError)?.error !== undefined;

  if (isError) {
    console.error(result);
  }
  return isError;
}

export function hasInternalServerError(result: FailableServerAction): result is ServerActionError {
  return hasFailed(result) && result.statusCode === 500;
}

export type FailableServerAction<T = any> = T | ServerActionError;
