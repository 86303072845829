import { Card, CardContent, CardTitle } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { FORMAT_PATTERN, formatToHumanReadable } from '@utils';
import { AttemptInProgress } from '../../../domain/attempt/attempt';
import { AttemptCriteria } from './attempt-criteria';
import { GiveUpAttemptButton } from './give-up-attempt-button';
import { StartEvaluationButton } from './start-evaluation-button';

export const AttemptInProgressCard = ({
  attempt,
  locale,
}: {
  attempt: AttemptInProgress;
  locale: string;
}): JSX.Element => {
  const { t } = useClientTranslations();

  const startedAt = formatToHumanReadable(attempt.startedAt, locale, FORMAT_PATTERN.DATE_FORMAT_COMPACT);

  return (
    <Card data-testid="attempt-in-progress-card" className="mobile:gap-lg flex flex-col justify-between">
      <div className="gap-md flex flex-col">
        <CardTitle className="leading-xl text-xl">{t('project-details.last-attempt.title')}</CardTitle>
        <CardContent className="gap-xs flex flex-col">
          <AttemptCriteria
            label={t('project-details.last-attempt.status')}
            value={t('project-details.last-attempt.status.in-progress')}
          />
          <AttemptCriteria label={t('project-details.last-attempt.starting-date')} value={startedAt} />
        </CardContent>
      </div>
      <div className="gap-sm flex w-full flex-col items-center sm:flex-row">
        <StartEvaluationButton actions={attempt._links} className="mobile:w-full mobile:grow mobile:shrink basis-1/2" />
        <GiveUpAttemptButton actions={attempt._links} className="mobile:w-full mobile:grow mobile:shrink basis-1/2" />
      </div>
    </Card>
  );
};
