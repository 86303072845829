'use client';

import { useState } from 'react';
import { BackButton } from '@design-system';
import { ProjectWithLastAttempt } from '../../domain/project-details/project-details';
import { useFetchWithInterval } from '../../server-actions';
import { ProjectAbstractCard } from './abstract-card/project-abstract-card';
import { EvaluationCard } from './evaluation-card/evaluation-card';
import { LastAttemptCardFactory } from './last-attempt-card/attempt';
import { StartAnAttemptButton } from './last-attempt-card/start-an-attempt-button';
import { ProjectSkillsCard } from './skills-card/project-skills-card';

export function ProjectDetails({
  initialProject,
  locale,
  fetchProjectDetails,
  deactivateFetching,
}: {
  initialProject: ProjectWithLastAttempt;
  locale: string;
  deactivateFetching?: boolean;
  fetchProjectDetails: (id: string) => Promise<ProjectWithLastAttempt>;
}): JSX.Element {
  const [project, setProject] = useState<ProjectWithLastAttempt>();
  useFetchWithInterval<ProjectWithLastAttempt>({
    serverAction: () => fetchProjectDetails(initialProject.id),
    setValue: setProject,
    initialValue: initialProject,
    deactivate: deactivateFetching,
  });

  if (!project) {
    return <></>;
  }

  return (
    <div className="gap-md gap-lg flex w-full flex-col">
      <div className="gap-md xs:items-center xs:flex-row flex flex-col justify-start">
        <BackButton className="flex sm:hidden" />
        <h3 className="leading-2xl shrink grow text-xl sm:text-3xl">{project.name}</h3>
        <StartAnAttemptButton actions={project._links} />
      </div>

      <div className="gap-sm flex w-full flex-col lg:flex-row">
        <div className="flex w-full lg:w-1/2">
          <ProjectAbstractCard project={project} />
        </div>
        <div className="gap-md gap-sm flex h-fit w-[94vw] flex-col overflow-hidden lg:w-1/2">
          <LastAttemptCardFactory project={project} locale={locale} />
          <EvaluationCard lastAttempt={project.lastAttempt} methods={project.evaluationMethods} />
          <ProjectSkillsCard project={project} />
        </div>
      </div>
    </div>
  );
}
