import { AttemptStatus } from '../../../domain/attempt/attempt';
import { ProjectWithLastAttempt } from '../../../domain/project-details/project-details';
import { AttemptAbandonedCard } from './attempt-abandoned';
import { AttemptUnderEvaluationCard } from './attempt-under-evaluation';
import { AttemptInProgressCard } from './attempt-in-progress';
import { AttemptFailedCard } from './attempt-failed';
import { AttemptSuccessfulCard } from './attempt-successful';

export const LastAttemptCardFactory = ({
  project,
  locale,
}: {
  project: ProjectWithLastAttempt;
  locale: string;
}): JSX.Element => {
  const attempt = project.lastAttempt;

  if (!attempt) {
    return <></>;
  }

  switch (attempt.status) {
    case AttemptStatus.IN_PROGRESS:
      return <AttemptInProgressCard attempt={attempt} locale={locale} />;

    case AttemptStatus.UNDER_EVALUATION:
      return <AttemptUnderEvaluationCard attempt={attempt} locale={locale} />;

    case AttemptStatus.ABANDONED:
      return <AttemptAbandonedCard attempt={attempt} locale={locale} />;

    case AttemptStatus.FAILED:
      return <AttemptFailedCard attempt={attempt} locale={locale} />;

    case AttemptStatus.SUCCESSFUL:
      return <AttemptSuccessfulCard attempt={attempt} locale={locale} />;

    default:
      return <></>;
  }
};
