import { useClientTranslations } from '@core-systems/i18n';
import { Button } from '@design-system';
import { Link } from '../../../domain';
import { useHandleLink } from '../../../server-actions';
import { LoadingButton } from '../loading-button';

export const StartEvaluationButton = ({
  actions,
  className,
}: {
  actions: { startEvaluation?: Link };
  className?: string;
}): JSX.Element => {
  const { handleLink, isMutating } = useHandleLink();
  const { t } = useClientTranslations();

  if (actions.startEvaluation === undefined) {
    return <></>;
  }

  const startEvaluation = actions.startEvaluation;
  return isMutating ? (
    <LoadingButton message={t('loading.attempt.waiting')} variant="secondary" className={className} />
  ) : (
    <Button
      data-testid="start-evaluation"
      className={className}
      variant="primary"
      onClick={() => handleLink(startEvaluation)}
    >
      {t('project-details.last-attempt.start-evaluation')}
    </Button>
  );
};
