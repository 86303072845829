'use client';
import { useClientTranslations } from '@core-systems/i18n';
import { I18nNamespace } from '../../infrastructure/i18n-namespaces';
import { DownloadButton } from './download-button';

export const DownloadResourcesButton = ({ href, className }: { href?: string; className?: string }): JSX.Element => {
  const { t } = useClientTranslations(I18nNamespace.PROJECTS);

  if (!href) {
    return <></>;
  }

  return (
    <DownloadButton dataTestId="download-resources" href={href} className={className}>
      {t('project-details.download-resources', { ns: I18nNamespace.PROJECTS })}
    </DownloadButton>
  );
};
