'use client';
import { Card, CardContent, CardTitle } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { Attempt, attemptIsUnderEvaluation, EvaluationStepStatus, EvaluationTimeline } from '../../../domain';
import { EvaluationMethodsStepper } from './evaluation-methods-stepper';
import { EvaluationTimelineStepper } from './evaluation-timeline-stepper';
import { RequestReviewButton } from './request-review-button';

export const EvaluationCard = ({
  lastAttempt,
  methods,
}: {
  lastAttempt?: Attempt;
  methods?: string[];
}): JSX.Element => {
  return (
    <div className="gap-md flex basis-1/2 flex-col">
      {attemptIsUnderEvaluation(lastAttempt) ? (
        <EvaluationStepCard steps={lastAttempt.evaluation.steps} />
      ) : (
        <EvaluationMethodsPreviewCard methods={methods} />
      )}
    </div>
  );
};

const EvaluationMethodsPreviewCard = ({ methods }: { methods?: string[] }): JSX.Element => {
  const { t } = useClientTranslations();

  if (methods === undefined) {
    return <></>;
  }

  return (
    <Card data-testid="evaluation-methods-card" className="gap-md flex h-full w-full flex-col">
      <CardTitle className="text-xl font-bold leading-relaxed">
        {t('project-details.evaluation-methods.preview.title')}
      </CardTitle>
      <CardContent>
        <EvaluationMethodsStepper evaluationMethods={methods} />
      </CardContent>
    </Card>
  );
};

const EvaluationStepCard = ({ steps }: { steps: EvaluationTimeline }): JSX.Element => {
  const { t } = useClientTranslations();

  const currentStep = steps.find((step) => step.status === EvaluationStepStatus.CURRENT);
  const requestReviewLink = currentStep?._links?.requestReview;
  return (
    <Card data-testid="evaluation-methods-card" className="gap-md flex h-full w-full flex-col">
      <CardTitle className="text-xl font-bold leading-relaxed">
        {t('project-details.evaluation-methods.stepper.title')}
      </CardTitle>
      <CardContent className="gap-md flex flex-col">
        <EvaluationTimelineStepper evaluationTimeline={steps} />
        <RequestReviewButton requestReviewLink={requestReviewLink} />
      </CardContent>
    </Card>
  );
};
