import { Skill } from '../../domain';
import { SkillElement } from './skill-element';

export function SkillList({
  skills,
  display = 'vertical',
}: {
  skills: Skill[];
  display?: 'vertical' | 'horizontal';
}): JSX.Element {
  return (
    <div className={`gap-xs flex flex-wrap ${display === 'vertical' ? 'flex-col' : 'flex-row'}`}>
      {skills.map(({ name: skillName, acquired }: { name: string; acquired: boolean }) => (
        <SkillElement
          key={skillName}
          name={skillName}
          acquired={acquired}
          className={`${display === 'vertical' ? 'w-full' : 'w-[384px] overflow-hidden'}`}
        />
      ))}
    </div>
  );
}
