export enum AuthError {
  UNABLE_TO_PERFORM_REQUEST = 'UnableToPerformRequest',
  AUTH_REFRESH_TOKENS_ERROR = 'RefreshTokensError',
  AUTH_REFRESH_TOKEN_EXPIRED = 'RefreshTokenExpired',
  UNHANDLED_SERVER_ERROR = 'UnhandledServerError',
  INVALID_CREDENTIALS = 'InvalidCredentials',
  INVALID_AUTHORIZATION_CODE = 'InvalidAuthorizationCode',
  MISSING_DATA_IN_RESPONSE = 'MissingDataInResponse',
  WRONG_DATA_IN_RESPONSE = 'WrongDataInResponse',
  NOT_FETCHED_YET = 'NotFetchedYet',
}
