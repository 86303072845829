'use client';

import { Stepper } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { I18nNamespace } from '../../../infrastructure/i18n-namespaces';

export const EvaluationMethodsStepper = ({ evaluationMethods }: { evaluationMethods: string[] }): JSX.Element => {
  const { t } = useClientTranslations();

  const steps = evaluationMethods.map((method) => {
    const stepTitle = t(`project-details.evaluation-methods.preview.${method}`, { ns: I18nNamespace.PROJECTS });

    return {
      variant: 'primary' as 'primary',
      title: stepTitle,
    };
  });

  return <Stepper steps={steps}></Stepper>;
};
