'use client';

import { Check, GraduationCap } from 'lucide-react';
import { TruncatedTextWithTooltip } from '@design-system';
import { cn } from '@utils';

export const SkillElement = ({
  name,
  acquired,
  className,
}: {
  name: string;
  acquired: boolean;
  className?: string;
}): JSX.Element => {
  return (
    <div className={cn('flex w-full', className)}>
      <SkillIcon acquired={acquired} />
      <div
        className={`bg-secondary-background-transparent text-primary text-md flex w-full items-center self-stretch overflow-hidden rounded px-2`}
      >
        <TruncatedTextWithTooltip text={name} />
      </div>
    </div>
  );
};

const SkillIcon = ({ acquired }: { acquired: boolean }): JSX.Element => {
  if (acquired) {
    return (
      <div className="bg-success flex h-full items-center justify-start rounded-bl rounded-tl p-2">
        <Check size={16} className="text-primary" />
      </div>
    );
  }

  return (
    <div className="bg-primary flex h-full items-center justify-start rounded-bl rounded-tl p-2">
      <GraduationCap size={16} className="text-background" />
    </div>
  );
};
