'use client';

import { Card } from '@design-system';
import { RushSummary } from '../../domain';
import { GoToRushButton } from './go-to-rush-button';
import { RegisterButton } from './register-to-rush-button';
import { UnregisterButton } from './unregister-from-rush-button';

export const RushBar = ({ rush }: { rush?: RushSummary }): JSX.Element => {
  if (!rush) {
    return <></>;
  }

  return (
    <Card className="flex w-full flex-col" data-testid="rush-bar">
      <div className="gap-md inline-flex flex-col items-start justify-start self-stretch sm:flex-row sm:items-center">
        <div className="inline-flex shrink grow items-start justify-start">
          <p className="text-base font-semibold">{rush.name}</p>
        </div>
        <div className="gap-xs xs:flex-row flex w-full flex-col items-center justify-start sm:w-auto">
          <RegisterButton link={rush._links.register} className="gap-sm py-sm px-md w-full sm:w-auto" />
          <UnregisterButton link={rush._links.unregister} className="gap-sm py-sm px-md w-full sm:w-auto" />
          <GoToRushButton rushId={rush.id} className="gap-sm py-sm px-md w-full sm:w-auto" />
        </div>
      </div>
    </Card>
  );
};
