'use client';

import { CircleCheckBig } from 'lucide-react';
import { useState } from 'react';
import { useClientTranslations } from '@core-systems/i18n';
import { DailyReviewStats } from '../../domain';
import { useFetchWithInterval } from '../../server-actions';
import { DailyReviewStatsCard } from './daily-review-stats-card';
import { ReviewToPerform, ReviewToPerformCard } from './reviews-to-perform-card';

export const ReviewsSection = ({
  stats,
  initialReviews,
  fetchReviews,
  deactivateFetching,
}: {
  initialReviews: ReviewToPerform[];
  fetchReviews: () => Promise<ReviewToPerform[]>;
  stats?: DailyReviewStats;
  deactivateFetching?: boolean;
}): JSX.Element => {
  const { t } = useClientTranslations();
  const [reviews, setReviews] = useState<ReviewToPerform[]>([]);
  useFetchWithInterval<ReviewToPerform[]>({
    serverAction: fetchReviews,
    setValue: setReviews,
    initialValue: initialReviews,
    deactivate: deactivateFetching,
  });

  return (
    <div className="gap-md mobile:gap-md flex flex-col">
      <div className="gap-xs flex flex-row items-center">
        <CircleCheckBig width={24} height={24} />
        <h2 className="text-xl">{t('homepage.reviews-to-perform.title', { ns: 'learning' })}</h2>
      </div>
      <DailyReviewStatsCard stats={stats} />
      <ReviewToPerformCard reviews={reviews} />
    </div>
  );
};
