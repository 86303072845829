'use client';
import { FileDown } from 'lucide-react';
import { useClientTranslations } from '@core-systems/i18n';
import { cn } from '@utils';
import { Button } from '@design-system';
import { useClientSideEnv } from '@core-systems/client-side-configuration';
import { I18nNamespace } from '../../infrastructure/i18n-namespaces';

export const DownloadSubjectButton = ({ href, className }: { href?: string; className?: string }): JSX.Element => {
  const { t } = useClientTranslations(I18nNamespace.PROJECTS);
  const { fileCdnUri } = useClientSideEnv();

  if (!href) {
    return <></>;
  }

  return (
    <Button
      variant="tertiary"
      data-testId="download-subject"
      Icon={FileDown}
      href={fileCdnUri + href}
      className={cn('w-full shrink grow basis-1/2 sm:w-auto', className)}
      download
    >
      {t('rush-details.download-subject', { ns: I18nNamespace.PROJECTS })}
    </Button>
  );
};
