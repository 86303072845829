'use client';

import { useRouter } from 'next/navigation';
import { Button } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { I18nNamespace } from '../../infrastructure/i18n-namespaces';
import { useHandleLink } from '../../server-actions/hooks/useHandleLink';
import { ReviewToPerform } from './reviews-to-perform-card';

export const ReviewCard = ({
  review: {
    reviewee: { firstName, lastName },
    projectName,
    stepId,
    _links: { startReview },
  },
}: {
  review: ReviewToPerform;
}): JSX.Element => {
  const { t } = useClientTranslations();
  const { push } = useRouter();
  const { handleLink } = useHandleLink();

  const handleStartReview = async (): Promise<void> => {
    await handleLink(startReview);
    push(`/reviews/${stepId}`);
  };

  return (
    <div className="mobile:flex-col desktop:flex-row desktop:items-center flex gap-4">
      <div className="gap-xxs inline-flex grow flex-col items-start justify-start">
        <p className="text-base font-bold leading-5">
          {t('homepage.reviews.reviewee', {
            firstName,
            lastName,
            ns: I18nNamespace.LEARNING,
          })}
        </p>
        <p className="text-subtext text-sm font-normal leading-5">
          {t('homepage.reviews.project', {
            targetName: projectName,
            ns: I18nNamespace.LEARNING,
          })}
        </p>
      </div>
      <Button className="mobile:w-full px-4 py-2" data-testid="start-review" onClick={handleStartReview}>
        {t('homepage.reviews.start', { ns: I18nNamespace.LEARNING })}
      </Button>
    </div>
  );
};
